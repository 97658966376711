body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', Arial, sans-serif;
    background: #000000FF;
  }
  
  .page {
    padding: 50px;
    text-align: center;
  }
  
  .header {
    font-family: 'Roboto', sans-serif;
    font-size: 4.2rem;
    line-height: 4rem;
    font-weight: 700;
    color: #74FB8DFF;
    margin-top: 6.9rem !important;
    margin-bottom: 2rem !important;
  }
  
  .subtext1 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-weight: 400;
    color: #74FB8DFF;
    margin-bottom: 3rem !important;
  }
  
  .subtext2 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.3rem;
    line-height: 2rem;
    font-weight: 700;
    color: #74FB8DFF;
    margin-bottom: 3rem !important;
  }
  
  .container {
    margin-top: 3rem;
    margin-bottom: 3rem;
    background: #000000FF;
    border-radius: 6px;
    border: 1px solid #74FB8DFF;
    box-shadow: 0px 0px 1px #171a1f, 0px 0px 2px #171a1f;
    padding: 20px;
  }
  
  .container-content {
    font-family: 'Roboto', sans-serif;
    font-size: 0.9rem;
    line-height: 1.4rem;
    font-weight: 400;
    color: #74FB8DFF;
  }
  
  .eth-address-input::placeholder {
    color: #74FB8DFF !important;
  }
  
  #twitterAuthBtn:hover {
    background-color: #78F2A9 !important;
    border-color: #78F2A9 !important;
  }
  
  .section-spacer {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }